<template>
 <div id="ShoppingCartEmpty.vue">
  <Navigation/>
  <ShoppingCartEmpty />
  <Partners />
  <Footer />
 </div>
</template>

<script>
import ShoppingCartEmpty from "../components/ShoppingCartEmpty.vue";
import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import Navigation from "../../home/components/Navigation.vue";
export default {
 name: "ShoppingCartEmpty.vue",
 components: { ShoppingCartEmpty, Partners, Footer, Navigation },
};
</script>

<style lang="sass" scoped></style>
