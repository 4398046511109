<template>
 <div id="ShoppingCartEmpty">
  <b-container>
   <b-row>
    <b-col>
     <div class="HeaderConfigProduct">
      <h4><i class="fa fa-shopping-cart"></i>Review & Checkout</h4>
     </div>
    </b-col>
   </b-row>
  </b-container>

  <b-container>
   <b-row>
    <b-col lg="8">
     <div class="view-cart-empty">
      <div class="img">
       <img src="../assets/shopping-cart-empty.svg" alt="" />
      </div>
      <div class="message"><h4>Your Shopping Cart Is Empty</h4></div>
      <div class="continueShopping">
       <button>Continue Shopping</button>
      </div>
     </div>
    </b-col>
    <b-col lg="4">
     <div class="scrollingPanelContainer">
      <div class="title-order-summary">
       <h5>Order Summary</h5>
      </div>
      <div class="order-summary">
       <div class="product-heading">
        <span>Subtotal</span>
        <span>$00.00 </span>
       </div>
       <br />
       <div class="summary-totals">
        <div class="sectiion-list">
         <span>Taxes (VAT) @ 00.00%:</span> <span> $00.00 </span>
        </div>
       </div>
       <hr class="hr" />
       <div class="total-due-today">
        <span> Total Amount</span>
        <span>$00.00</span>
       </div>
      </div>
      <div class="btnDomainContinue">
       <b-container>
        <b-row>
         <b-col>
          <button><i class="fas fa-angle-left"></i> Continue Shopping</button>
         </b-col>
         <b-col>
          <button>
           Checkout
           <i class="fas fa-angle-right"></i>
          </button>
         </b-col>
        </b-row>
       </b-container>
      </div>
      <div class="PaymentCards">
       <b-container>
        <b-row cols-lg="4">
         <b-col>
          <div class="img"><img src="../assets/payment.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-1.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-3.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-4.png" alt="" /></div>
         </b-col>
        </b-row>
       </b-container>
      </div>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {
 name: "ShoppingCartEmpty",
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#ShoppingCartEmpty {
 padding: 150px 0;
 @mixin style-headers {
  padding: 10px 20px;
  background: map-get($branding, them-color-option-tow);
  border-radius: 2px;
 }
 @mixin style-text-header {
  color: blanchedalmond;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
 }
 @mixin style-icon-befor-header {
  margin-right: 5px;
  font-size: 19px;
 }
 @mixin style-list {
  font-size: 15px;
  border-bottom: 1px solid #f1ebeb;
  padding: 10px;
  margin-bottom: 0;
  color: #5b5858;
 }
 @mixin product-info {
  box-shadow: 0 0 13px 0 #00000042;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fafafa;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #efefef;
  margin: 8px 0 10px 0;
 }
 @mixin text-in-span-summary {
  font-size: 13px;
  color: #000;
  font-weight: 600;
 }
 .HeaderConfigProduct {
  @include style-headers;
  h4 {
   @include style-text-header;
   .fa-shopping-cart {
    @include style-icon-befor-header;
   }
  }
 }
 .view-cart-empty {
  padding: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  .img {
   display: flex;
   justify-content: center;
   img {
    width: 35%;
   }
  }
  .message {
   margin: 10px 0;
   h4 {
    font-size: 20px;
    color: #666;
   }
  }
  .continueShopping {
   margin-top: 15px;
   button {
    border: none;
    padding: 8px 120px;
    color: #fff;
    background: #0d235a;
    border-radius: 4px;
   }
  }
 }
 .scrollingPanelContainer {
  .title-order-summary {
   @include style-headers;
   margin-top: 5px;
   padding: 10px 31px;
   h5 {
    @include style-text-header;
    font-size: 15px;
    text-align: center;
   }
  }
  .order-summary {
   @include product-info;
   padding-top: 35px;
   padding-bottom: 35px;
   .hr {
    height: 1px;
    color: black;
   }
  }
  .product-heading {
   display: flex;
   justify-content: space-between;
   span {
    @include text-in-span-summary;
    font-size: 15px;
    font-weight: 500;
   }
  }
  .summary-totals {
   .sectiion-list {
    display: flex;
    justify-content: space-between;
    span {
     @include text-in-span-summary;
     font-size: 15px;
     font-weight: 500;
    }
   }
  }
  .total-due-today {
   display: flex;
   justify-content: space-between;
   span {
    @include text-in-span-summary;
    font-size: 22px;
    font-weight: 900;
   }
  }
  .btnDomainContinue {
   margin: 13px 0;
   button {
    border: none;
    font-size: 13px;
    padding: 7px 10px;
    border-radius: 2px;
    color: #fff;
    background-color: #0d235a;
    width: 100%;
   }
  }
  .PaymentCards {
   margin: 20px 0;
  }
 }
}
</style>
